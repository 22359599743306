.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    } */

@media screen and (min-width: 320px) {
  .swiper {
    height: 20rem;
  }
}

@media screen and (min-width: 768px) {
  .swiper {
    height: 17rem;
  }
}
@media screen and (min-width: 1024px) {
  .swiper {
    height: 20rem;
  }
}
@media screen and (min-width: 1280px) {
  .swiper {
    height: 25rem;
  }
}
@media screen and (min-width: 1536px) {
  .swiper {
    height: 30rem;
  }
}

/* 
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-prev:after {
  color: rgba(14, 140, 231, 0.534);
}
.swiper-button-prev:before {
  color: rgba(14, 140, 231, 0.534);
} */
